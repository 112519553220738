'use client'

import { useCallback, useLayoutEffect, useRef } from 'react'
import { Transition } from '@headlessui/react'
import { shallowEqual } from '@xstate/react'
import {
  circIn,
  easeInOut,
  easeOut,
  motion,
  useMotionValueEvent,
  useScroll,
  useSpring,
  useTransform,
} from 'framer-motion'
import { twJoin } from 'tailwind-merge'

import { SectionDefinition } from '@/data/shared'
import { PieProgress } from '../PieProgress'
import { StepperContext } from '../StepperWrapper/StepperContext'

export function StepsSection({ definition }: { definition: SectionDefinition<'steps'> }) {
  const containerRef = useRef<HTMLDivElement>(null)
  const { stepsMatrix: steps } = definition

  const [, send] = StepperContext.useActor()
  const { step, change, initialized, navigatorVisible, windowSize, maxSteps } = StepperContext.useSelector((state) => {
    const { change, step, windowSize, maxSteps } = state.context
    const initialized = !state.matches('Stepper.Uninitialized')
    const navigatorVisible = state.matches('Navigator.Visible')
    return { change, step, initialized, navigatorVisible, windowSize, maxSteps }
  }, shallowEqual)

  const stepScale = 0.5
  const paddedStepsLength = steps.length + 1 / stepScale

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end'],
  })

  const updateNavigator = useCallback(
    (progress: number) => {
      const pageUnit = 1 / paddedStepsLength
      const beforeNav = progress < 0.25 * pageUnit
      const afternav = progress > 1 - 0.5 * pageUnit
      const visibleNav = !beforeNav && !afternav

      if (navigatorVisible && !visibleNav) {
        send({ type: 'Hide navigator' })
      } else if (maxSteps > 1 && !navigatorVisible && visibleNav) {
        send({ type: 'Show navigator' })
      }
    },
    [navigatorVisible, paddedStepsLength, send, maxSteps],
  )

  useMotionValueEvent(scrollYProgress, 'change', updateNavigator)

  const scaleToWindow = useCallback(
    (startSize: number, adjustment = 1) => {
      if (typeof window === 'undefined') {
        return 20
      }
      return (Math.sqrt(Math.pow(windowSize[0], 2) + Math.pow(windowSize[1], 2)) / startSize) * adjustment
    },
    [windowSize],
  )

  const scrollProgress = useTransform(scrollYProgress, (v) =>
    Math.min(Math.max(v * paddedStepsLength - 1, 0), steps.length),
  )

  const pieProgress = useTransform(scrollProgress, [0, steps.length], [0, 1], { clamp: true })
  const y = useTransform(scrollProgress, [0, 1], ['25%', '50%'], { clamp: true, ease: easeOut })
  const opacity = useTransform(scrollProgress, [0, 1], [0, 1], { clamp: true, ease: easeInOut })
  const easedScale = useTransform(scrollYProgress, [0.9, 0.95, 1], [1, 1.5, scaleToWindow(128, 2.5)], {
    clamp: true,
    ease: circIn,
  })
  const scale = useSpring(easedScale, { stiffness: 600, damping: 50, mass: 0.5 })

  const scrollStep = useTransform(scrollProgress, Math.floor)
  useMotionValueEvent(scrollStep, 'change', (s) => {
    if (s !== step) {
      send({ type: 'Change step', step: s })
    }
  })

  useLayoutEffect(() => {
    updateNavigator(scrollYProgress.get())
  }, [scrollYProgress, updateNavigator])

  useLayoutEffect(() => {
    if (!initialized) {
      send({ type: 'Initialize stepper', maxSteps: steps.length, step: scrollStep.get() })
    }
  }, [steps.length, send, initialized, scrollStep])

  return (
    <div
      ref={containerRef}
      data-section-id="steps"
      style={
        {
          '--rowHeight': `${stepScale * 100}svh`,
          '--finalRowHeight': `span ${1 + 1 / stepScale} / span ${1 + 1 / stepScale}`,
        } as React.CSSProperties
      }
      className="relative grid -scroll-mt-4 auto-rows-[--rowHeight] grid-rows-[--rowHeight]"
    >
      <motion.div
        data-scroll-step="1"
        className="sticky top-0 flex items-center justify-center pb-[12rem] sm:pb-0"
        style={{ y, opacity }}
      >
        <div className="relative grid w-full max-w-xl auto-rows-auto grid-cols-1 content-center justify-items-center gap-y-10 text-neutral-900 sm:gap-24 md:grid-cols-[auto_1fr] md:grid-rows-1 md:gap-14 lg:max-w-2xl">
          <PieProgress
            value={pieProgress}
            steps={steps.length}
            className={twJoin(
              'col-start-1 row-start-1 block h-32 w-32',
              step >= steps.length && 'bg-neutral-850 [section.dark_&]:bg-neutral-50',
            )}
            style={{ scale }}
          />
          {steps.map(({ id, heading, copy }, index) => (
            <Transition
              key={id}
              className="col-start-1 row-start-2 min-h-[12rem] text-neutral-850 md:col-start-2 md:row-start-1 [section.dark_&]:text-neutral-50"
              show={step === index + 1 || (step === 0 && index === 0)}
              enter="transition-all duration-500 ease-in-out transform"
              enterFrom={twJoin(
                'opacity-0',
                change === 'down' ? '-translate-x-8' : change === 'up' ? 'translate-x-8' : 'translate-x-0',
              )}
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-500 ease-in-out transform delay-100"
              leaveFrom="opacity-50 translate-x-0"
              leaveTo={twJoin(
                'opacity-0',
                change === 'down' ? 'translate-x-8' : change === 'up' ? '-translate-x-8' : 'translate-x-0',
              )}
            >
              <h3 className="text-center font-sans text-xl font-semibold uppercase tracking-widest md:text-left md:text-4xl">
                {heading}
              </h3>
              <div className="mt-4 text-justify leading-relaxed md:mt-6" dangerouslySetInnerHTML={{ __html: copy }} />
            </Transition>
          ))}
        </div>
      </motion.div>
      {Array.from({ length: steps.length - 1 }).map((_, index) => (
        <div
          key={index}
          data-scroll-step={index + 2}
          className="relative h-full -scroll-mt-4 last:row-[--finalRowHeight]"
        />
      ))}
    </div>
  )
}
