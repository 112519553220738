'use client'

import { Disclosure } from '@headlessui/react'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { SectionDefinition } from '@/data/shared'

export function AccordionSection({ definition }: { definition: SectionDefinition<'accordion'> }) {
  return (
    <motion.div layout="size" className="z-10 mb-24 bg-neutral-50/10 xl:ml-28">
      <LayoutGroup>
        {definition.accordionMatrix.map(({ id, heading, copy }) => (
          <Disclosure
            key={id}
            as={motion.div}
            layout="position"
            transition={{ type: 'spring', stiffness: 350, damping: 30 }}
          >
            {({ open }) => (
              <>
                <Disclosure.Button className="group flex w-full items-center justify-between border-t border-t-neutral-850/50 focus-visible:outline-none md:h-24 md:border-t-2 md:border-t-neutral-850/25">
                  <h4 className="mb-8 mt-6 text-left font-sans text-xl font-semibold leading-tight tracking-tight underline decoration-neutral-850/0 transition-[text-decoration-color] duration-200 sm:text-3xl md:mt-6 md:text-4xl md:group-hocus:decoration-neutral-850/20">
                    {heading}
                  </h4>
                  <div className="flex h-16 w-10 shrink-0 items-center justify-center sm:h-20 md:h-24">
                    <svg
                      className="absolute aspect-square w-6 md:w-10"
                      viewBox="0 0 40 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line x1="40" y1="2" y2="2" stroke="currentColor" strokeWidth="4" />
                    </svg>
                    <svg
                      className={twMerge(
                        'absolute aspect-square w-6 opacity-100 transition-transform duration-300 -rotate-90 md:w-10',
                        open ? 'scale-x-0' : 'scale-x-100',
                      )}
                      viewBox="0 0 40 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line x1="40" y1="2" y2="2" stroke="currentColor" strokeWidth="4" />
                    </svg>
                  </div>
                </Disclosure.Button>
                <AnimatePresence mode="wait">
                  <Disclosure.Panel
                    as={motion.div}
                    layout="position"
                    className="panel mt-4 flex flex-col pb-12 sm:mt-0 md:pr-40"
                    initial={{ y: -40, opacity: 0 }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 0.1, type: 'spring', stiffness: 400, damping: 30 },
                    }}
                    exit={{ y: -40, opacity: 0 }}
                  >
                    <div className="prose-sm w-full" dangerouslySetInnerHTML={{ __html: copy }} />
                  </Disclosure.Panel>
                </AnimatePresence>
              </>
            )}
          </Disclosure>
        ))}
      </LayoutGroup>
    </motion.div>
  )
}
